import { CSSProperties, MouseEventHandler } from "react";

export interface MaxBottomProps {
  title?: string;
  onClick?: MouseEventHandler;
  loading?: boolean | undefined | null;
  lock?: boolean | undefined | null;
  className?: string | undefined;
  status?: StatusMaxButton | undefined;
  style?: CSSProperties | undefined;
}

export enum StatusMaxButton {
  submit = "btn-purple-submit",
  deposit = "btn-purple-deposit",
  withdraw = "btn-purple-withdraw",
  bluesubmit = "background-blue-linear",
  playgame = "btn-playgame",
  back = "btn-purple-back",
  close = "btn-purple-close",
  tryplay = "btn-try-play",
  promotion = "bg-purple-submit",
  non = "font-white",
}

export function MaxButton(props: MaxBottomProps) {
  const { onClick, loading, title, className, status, lock, style } = props;

  return (
    <button
      style={style}
      className={`btn ${status} ${className}`}
      onClick={onClick}
      disabled={lock ? lock : loading ?? false}
    >
      {!lock && (
        <>
          {loading && (
            <>
              <span className="spinner-border spinner-border-sm"></span>
            </>
          )}
          {!loading && (
            <>
              <div>{title}</div>
            </>
          )}
        </>
      )}

      {lock && (
        <>
          <div>{title}</div>
        </>
      )}
    </button>
  );
}
