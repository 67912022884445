import { wrapper } from "../lib/store";
import "bootstrap/scss/bootstrap.scss";
import "../styles/globals.scss";
import "../styles/margin.scss";
import "../styles/font.scss";
import "../styles/icon.scss";
import "../styles/view.scss";
import "../styles/align.scss";
import "../styles/navigation.scss";
import "../styles/loading.scss";
import "../styles/loading_black_screen.scss";
import "../styles/outreview.scss";
import "../styles/check_modal.scss";
import "../styles/tag.scss";
import "../styles/chat.scss";
import "../styles/animation_ugames.scss";
import "../styles/bg_animation_border.scss";
import { useEffect, useState } from "react";
import {
  CloudimageProvider,
  CloudimageProviderConfig,
} from "react-cloudimage-responsive";
import { useRouter } from "next/router";
import { alertWithMessage } from "components/modal/message_modal";
import { ToastProvider } from "react-toast-notifications";
import { CookiesProvider, withCookies } from "react-cookie";

declare global {
  interface Number {
    format: (n: number, x: number) => string;
  }
}

Number.prototype.format = function (n: number, x: number): string {
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
};

const App = (props: any) => {
  const { Component, pageProps } = props;
  const [loadBlockScreen, setLoadBlockScreen] = useState(false);
  const router = useRouter();
  const cloudimageConfig: CloudimageProviderConfig = {
    token: "aelwrzoecp",
    baseURL: "_sevenduckciio_/snambet/",
    presets: {
      xs: "(max-width: 575px)", // up to 575    PHONE
      sm: "(min-width: 576px)", // 576 - 767    PHABLET
      md: "(min-width: 768px)", // 768 - 991    TABLET
      lg: "(min-width: 992px)", // 992 - 1199   SMALL_LAPTOP_SCREEN
      xl: "(min-width: 1200px)", // from 1200    USUALSCREEN
    },
    lazyLoading: true,
    doNotReplaceURL: false,
    params: {
      force_format: "webp",
    },
  };

  useEffect(() => {
    router.events.on("routeChangeComplete", () => {
      setLoadBlockScreen(false);
      window.scrollTo(0, 0);
    });

    router.events.on("routeChangeStart", () => {
      setLoadBlockScreen(true);
    });
  }, []);

  const LoadingBlockScreen = (
    <div className="screen-loading">Loading&#8230;</div>
  );

  return (
    <>
      {loadBlockScreen && LoadingBlockScreen}
      <CookiesProvider>
        <ToastProvider placement="bottom-right">
          <CloudimageProvider config={cloudimageConfig}>
            <Component
              {...pageProps}
              {...props}
              alertWithMessage={alertWithMessage}
            />
          </CloudimageProvider>
        </ToastProvider>
      </CookiesProvider>
    </>
  );
};

export default wrapper.withRedux(withCookies(App));
