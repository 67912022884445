import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { AppReducerStateProps, store } from "lib/store";
import { memberActionTypes } from "lib/store/member/reducer";
import { MaxButton, StatusMaxButton } from "components/view/button";
import { useRouter } from "next/router";
import {
  CheckWithPrefixResponse,
  GetPrefixLevel,
} from "lib/infomation_vender/document";

export interface MessageModalProps {
  closeModal: Function;
  closeAllModal: Function;
  state: AppReducerStateProps;
  information: CheckWithPrefixResponse;
}

export const alertWithMessage = (message: string) => {
  store.dispatch({
    type: memberActionTypes.ACTION_OPEN_MODAL_WITH_MESSAGE_OPEN,
    payload: message,
  });
};

function MessageModal(props: MessageModalProps) {
  const { closeModal, closeAllModal } = props;
  const { isModalMessage, modalMessage, isLogin } = props.state.member;
  const router = useRouter();
  const prefix = GetPrefixLevel(props.information);

  return (
    <Modal
      {...props}
      size="lg"
      scrollable={true}
      contentClassName="view-background-black"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalMessage}
      onHide={() => {
        closeModal();
      }}
      dialogClassName="modal-alert"
    >
      <Modal.Header closeButton className="modal-header">
        <Modal.Title id="example-modal-sizes-title-lg">
          <div className="text-title center-row-content font-bold">
            <span>แจ้งเตือน</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="row center-row-content">{modalMessage}</div>
          <div className="swal2-icon swal2-error swal2-animate-error-icon flex-er">
            <span className="swal2-x-mark">
              <span className="swal2-x-mark-line-left"></span>
              <span className="swal2-x-mark-line-right"></span>
            </span>
          </div>
        </div>
        {"ไม่สามารถเริ่มเล่นเกมนี้ได้ เนื่องจากเกมที่ท่านเลือกไม่เข้าร่วมกิจกรรมโบนัสเครดิต กรุณาเลือกเล่นเกมที่ทางเราได้จัดเตรียมไว้ให้เท่านั้น" ==
          modalMessage && (
          <>
            <MaxButton
              title="เข้าสู่หน้าเกมโบนัสเครดิต"
              status={StatusMaxButton.submit}
              onClick={() => {
                closeAllModal();
                router.push(`/game_condition_promotion`);
              }}
            ></MaxButton>
          </>
        )}

        {!isLogin && (
          <>
            <MaxButton
              title="เข้าสู่ระบบ"
              status={StatusMaxButton.submit}
              onClick={() => {
                closeAllModal();
                router.push(`/login/${prefix}`);
              }}
            ></MaxButton>
            <MaxButton
              className="margin-top-10"
              title="สมัครสมาชิก"
              status={StatusMaxButton.back}
              onClick={() => {
                closeAllModal();
                router.push(`/register/${prefix}`);
              }}
            ></MaxButton>
          </>
        )}
        {modalMessage == "ท่านยังไม่ได้ระบุบัญชีธนาคาร" && (
          <>
            <MaxButton
              title="ระบุธนาคาร"
              status={StatusMaxButton.submit}
              onClick={() => {
                closeAllModal();
                router.push("/add_bank");
              }}
            ></MaxButton>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <div className="row">
          <div
            className="btn btn-purple-submit"
            onClick={() => {
              closeModal();
            }}
          >
            ปิด
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeModal: () => {
      dispatch({
        type: memberActionTypes.ACTION_OPEN_MODAL_WITH_MESSAGE_CLOSE,
      });
    },
    closeAllModal: () => {
      dispatch({
        type: memberActionTypes.ACTION_OPEN_MODAL_ALL_CLOSE,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
