import { GetMessageResponse } from "../../worker/api/get_message";

export const chatActionTypes = {
  ACTION_UPDATE_CHATS: "ACTION_UPDATE_CHATS",
  ACTION_OPEN_MODAL_CHAT: "ACTION_OPEN_MODAL_CHAT",
  ACTION_CLOSE_MODAL_CHAT: "ACTION_CLOSE_MODAL_CHAT",
};

export interface ChatInitialStateProps {
  chats: GetMessageResponse[] | null;
  isModalChatImage: boolean;
  chatImage: string;
}

const chatInitialState: ChatInitialStateProps = {
  chats: [],
  isModalChatImage: false,
  chatImage: "",
};

export default function reducer(
  state = chatInitialState,
  action: any
): ChatInitialStateProps {
  switch (action.type) {
    case chatActionTypes.ACTION_UPDATE_CHATS:
      return {
        ...state,
        chats: action.payload,
      };
    case chatActionTypes.ACTION_OPEN_MODAL_CHAT:
      return {
        ...state,
        isModalChatImage: true,
        chatImage: action.payload,
      };
    case chatActionTypes.ACTION_CLOSE_MODAL_CHAT:
      return {
        ...state,
        isModalChatImage: false,
        chatImage: "",
      };
    default:
      return state;
  }
}
