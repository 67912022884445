import { DefaultImage } from "./images_for_vender";

export const defaultImage = (): DefaultImage => {
  return {
    IconMenuHomePages: [
      {
        title: "คืนยอดเสีย",
        location: "/return_loss",
        pic: "ufaber1-return-loss.png",
        isDisable: true,
        isDisableBorderBackground: false,
      },
      {
        title: "ฝากเงิน",
        location: "",
        pic: "ufabet-savecash.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "ถอนเงิน",
        location: "",
        pic: "ufabet-bank.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "ตัวแทน",
        location: "/invite_agent",
        pic: "ufabet-invite-icon.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "โปรโมชั่น",
        location: "/all_promotion",
        pic: "ufabet-promotion.png",
        isDisable: true,
        isDisableBorderBackground: false,
      },
      {
        title: "เกมโบนัส",
        location: "/game_condition_promotion",
        pic: "ufabet-gamebonus2.png",
        isDisable: true,
        isDisableBorderBackground: false,
      },
      {
        title: "uGames",
        location: "/all_ugame",
        pic: "ufabet-diamond-icon.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "ประวัติ",
        location: "/transaction_transfer",
        pic: "ufabet-history.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },

      {
        title: "ประวัติเพชร",
        location: "/transaction_udiamond",
        pic: "ufabet-letter-icon.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "บทความ",
        location: "/blogs/all_blog",
        pic: "icon-tab-blogs.png",
        isDisable: true,
        isDisableBorderBackground: false,
      },
    ],
    IconMenuHomeOnePages: [
      {
        title: "คืนยอดเสีย",
        location: "/return_loss",
        pic: "icon-tab-return-loss.png",
        isDisable: true,
        isDisableBorderBackground: false,
      },
      {
        title: "โหลดแอป",
        location: "/download_app",
        pic: "icon-tab-download4.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "ตัวแทน",
        location: "/invite_agent",
        pic: "icon-tab-agent4.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "โปรโมชั่น",
        location: "/all_promotion",
        pic: "icon-tab-promotion4.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "เกมโบนัส",
        location: "/game_condition_promotion",
        pic: "icon-tab-gamepro4.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "เกมของฉัน",
        location: "/game_favorite",
        pic: "icon-tab-mygame4.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "เล่นล่าสุด",
        location: "/last_game_history",
        pic: "icon-tab-lastgame4.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "บทความ",
        location: "/blogs/all_blog",
        pic: "icon-tab-blogs.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "ประวัติ",
        location: "/all_history",
        pic: "history-snam-op.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "บัญชี",
        location: "/bank",
        pic: "bank-snam-op.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "แลกเครดิต",
        location: "/exchange",
        pic: "exchange-snam-op.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
      {
        title: "ออก",
        location: "/",
        pic: "logout-snam-op.png",
        isDisable: false,
        isDisableBorderBackground: false,
      },
    ],
    IconCampeGames: [
      {
        pic: "ufabet-campgame2.png",
        location: "/camps/ufabet",
        alt: "UFABET SPORT CASINO BETTING",
        flag: "",
        flagtitle: "",
        isEnable: false,
      },
      {
        pic: "lotto-campgame-4.png",
        location: "/camps/lottohot",
        alt: "lotto hot",
        flag: "tag-all-camp flag-event",
        flagtitle: "",
        isEnable: false,
      },
      {
        pic: "sbobet-campgame-7.png",
        location: "/camps/sbobet_sport",
        alt: "sbo",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "pg-campgame-7.png",
        location: "/camps/pg_gaming",
        alt: "PG",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "pp-campgame-5.png",
        location: "/camps/pragmatic_live_casino",
        alt: "pragmatic_live_casino",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "joker-campgame-5.png",
        location: "/camps/joker_gaming",
        alt: "joker_gaming",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "sa-campgame-5.png",
        location: "/camps/sa_gaming",
        alt: "sagame",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "ae-sexy-campgame-5.png",
        location: "/camps/sexy_gaming",
        alt: "aesexy",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "ag-campgame-7.png",
        location: "/camps/asia_gaming",
        alt: "AG asiagaming",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "evolution-campgame.png",
        location: "/camps/evolution_gaming",
        alt: "EVOLUTION GAMING",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "ebet-campgame.png",
        location: "/camps/ebet_casino",
        alt: "Ebet casino",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "jili-campgame.png",
        location: "/camps/jili_gaming",
        alt: "OneGame SLOT",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "fachai-campgame.png",
        location: "/camps/fachai_gaming",
        alt: "fachai slot game",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "jdb-campgame.png",
        location: "/camps/jdb_gaming",
        alt: "jdb slot game",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "kingmaker-campgame.png",
        location: "/camps/kingmaker_gaming",
        alt: "kingmaker slot game",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "rich88-campgame.png",
        location: "/camps/rich88_gaming",
        alt: "rich88 slot and table game",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "redtiger-campgame.png",
        location: "/camps/redtiger_gaming",
        alt: "Red Tiger SLOT GAME",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "spinix-campgame.png",
        location: "/camps/spinix_gaming",
        alt: "Spinix SLOT GAME",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "bng-campgame2.png",
        location: "/camps/bng_gaming",
        alt: "BNG SLOT GAME",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "cq9-campgame.png",
        location: "/camps/cq9_gaming",
        alt: "CQ9 SLOT GAME",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "onegame-campgame.png",
        location: "/camps/onegame_gaming",
        alt: "OneGame SLOT",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "dragoon-campgame-5.png",
        location: "/camps/dragoon_gaming",
        alt: "dragoon soft gaming",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "ygg-campgame-7.png",
        location: "/camps/ygg_gaming",
        alt: "ygg_gaming",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "yl-campgame-7.png",
        location: "/camps/yl_gaming",
        alt: "yl game",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "slotxo-campgame.png",
        location: "/camps/slotxo",
        alt: "slotxo สล็อตออนไลน์",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "naga-campgame.png",
        location: "/camps/naga_games",
        alt: "naga Games สล็ตออนไลน์",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "gclub-campgame2.png",
        location: "/camps/gclub_casino",
        alt: "GCLUB",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
      {
        pic: "ka-campgame.png",
        location: "/camps/ka_gaming",
        alt: "ka",
        flag: "",
        flagtitle: "",
        isEnable: true,
      },
    ],
    IconUdiamondEventHomePages: [
      {
        pic: "udiamond-ucheckin-3.png",
        bg: "shadow border-radius fade-color-blue",
        location: "/uGame/check_in",
        disable: false,
      },
      {
        pic: "udiamond-ubox-3.png",
        bg: "shadow border-radius fade-color-blue",
        location: "/uGame/open_ubox",
        disable: false,
      },
      {
        pic: "ugame_ucomment.png",
        bg: "shadow border-radius fade-color-blue",
        location: "/comment_all",
        disable: false,
      },
      {
        pic: "uspin-iphone-home-2.png",
        bg: "shadow border-radius fade-color-blue",
        location: "/uGame/uspin",
        disable: false,
      },
      {
        pic: "ugame-rps.png",
        bg: "shadow border-radius fade-color-blue",
        location: "/uGame/urps",
        disable: false,
      },
    ],
    IconCampeGamesOnePages: [
      {
        pic: "lotto-camps-op.png",
        location: "/camps/lottohot",
        alt: "LottoHot",
        flag: "tag-all-camp flag-event",
        flagtitle: "",
        venderPrefix: "lottohot",
        isEnable: false,
        isHisLobby: true,
      },
      {
        pic: "sbobet-camps-op.png",
        location: "/camps/sbobet_sport",
        alt: "SBOBET",
        flag: "",
        flagtitle: "",
        venderPrefix: "sbobet",
        isEnable: true,
        isHisLobby: true,
      },
      {
        pic: "pg-camps-op.png",
        location: "/camps/pg_gaming",
        alt: "PG SOFT",
        flag: "",
        flagtitle: "",
        isEnable: true,
        venderPrefix: "pg",
        isHisLobby: false,
      },
      {
        pic: "pp-camps-op.png",
        location: "/camps/pragmatic_live_casino",
        alt: "Pragmatic Play",
        flag: "",
        flagtitle: "",
        isEnable: true,
        isHisLobby: false,
        venderPrefix: "pp",
      },
      {
        pic: "joker-camps-op.png",
        location: "/camps/joker_gaming",
        alt: "Joker Gaming",
        flag: "",
        flagtitle: "",
        isEnable: true,
        isHisLobby: false,
        venderPrefix: "jk",
      },
      {
        pic: "sa-camps-op.png",
        location: "/camps/sa_gaming",
        alt: "SA Gaming",
        flag: "",
        flagtitle: "",
        venderPrefix: "sa",
        isEnable: true,
        isHisLobby: true,
      },
      {
        pic: "ae-camps-op.png",
        location: "/camps/sexy_gaming",
        alt: "AWC Sexy Gaming",
        flag: "",
        flagtitle: "",
        venderPrefix: "awcsexy",
        isEnable: true,
        isHisLobby: true,
      },
      {
        pic: "ag-camps-op.png",
        location: "/camps/asia_gaming",
        alt: "AG Asia Gaming",
        flag: "",
        flagtitle: "",
        venderPrefix: "ag",
        isEnable: true,
        isHisLobby: true,
      },
      {
        pic: "evolution-camps-op.png",
        location: "/camps/evolution_gaming",
        alt: "EVOLUTION GAMING",
        flag: "",
        flagtitle: "",
        venderPrefix: "evogame",
        isEnable: true,
        isHisLobby: true,
      },
      {
        pic: "ebet-camps-op.png",
        location: "/camps/ebet_casino",
        alt: "Ebet",
        flag: "",
        flagtitle: "",
        venderPrefix: "ebet",
        isEnable: true,
        isHisLobby: true,
      },
      {
        pic: "jili-camps-op.png",
        location: "/camps/jili_gaming",
        alt: "Jili",
        flag: "",
        flagtitle: "",
        isEnable: true,
        isHisLobby: false,
        venderPrefix: "jili",
      },
      {
        pic: "fachai-camps-op.png",
        location: "/camps/fachai_gaming",
        alt: "Fachai",
        flag: "",
        flagtitle: "",
        isEnable: true,
        isHisLobby: false,
        venderPrefix: "fc",
      },
      {
        pic: "jdb-camps-op.png",
        location: "/camps/jdb_gaming",
        alt: "JDB",
        flag: "",
        flagtitle: "",
        venderPrefix: "jdb",
        isEnable: true,
        isHisLobby: true,
      },
      {
        pic: "kingmaker-camps-op.png",
        location: "/camps/kingmaker_gaming",
        alt: "Kingmaker",
        flag: "",
        flagtitle: "",
        isEnable: true,
        isHisLobby: false,
        venderPrefix: "qm",
      },
      {
        pic: "rich88-camps-op.png",
        location: "/camps/rich88_gaming",
        alt: "Rich88",
        flag: "",
        flagtitle: "",
        venderPrefix: "rich88",
        isEnable: true,
        isHisLobby: true,
      },
      {
        pic: "redtiger-camps-op.png",
        location: "/camps/redtiger_gaming",
        alt: "Red Tiger",
        flag: "",
        flagtitle: "",
        isEnable: true,
        isHisLobby: false,
        venderPrefix: "qm",
      },
      {
        pic: "spinix-camps-op.png",
        location: "/camps/spinix_gaming",
        alt: "Spinix",
        flag: "",
        flagtitle: "",
        isEnable: true,
        isHisLobby: false,
        venderPrefix: "spinix",
      },
      {
        pic: "bng-camps-op.png",
        location: "/camps/bng_gaming",
        alt: "BNG Gamming",
        flag: "",
        flagtitle: "",
        venderPrefix: "bng",
        isEnable: true,
        isHisLobby: true,
      },
      {
        pic: "cq9-camps-op.png",
        location: "/camps/cq9_gaming",
        alt: "CQ9 Gaming",
        flag: "",
        flagtitle: "",
        venderPrefix: "cq9",
        isEnable: true,
        isHisLobby: true,
      },
      {
        pic: "onegame-camps-op.png",
        location: "/camps/onegame_gaming",
        alt: "OneGame",
        flag: "",
        flagtitle: "",
        isEnable: true,
        isHisLobby: false,
        venderPrefix: "qm",
      },

      {
        pic: "dragoon-camps-op.png",
        location: "/camps/dragoon_gaming",
        alt: "Dragoon Soft Gaming",
        flag: "",
        flagtitle: "",
        isEnable: true,
        venderPrefix: "dgf",
        isHisLobby: false,
      },
      {
        pic: "ygg-camps-op.png",
        location: "/camps/ygg_gaming",
        alt: "YGG Gaming",
        flag: "",
        flagtitle: "",
        isEnable: true,
        isHisLobby: false,
        venderPrefix: "atsygg",
      },
      {
        pic: "yl-camps-op.png",
        location: "/camps/yl_gaming",
        alt: "YL Gaming",
        flag: "",
        flagtitle: "",
        isEnable: true,
        isHisLobby: false,
        venderPrefix: "ylfishing",
      },
      {
        pic: "slotxo-camps-op.png",
        location: "/camps/slotxo",
        alt: "SLOTXO",
        flag: "",
        flagtitle: "",
        isEnable: true,
        isHisLobby: false,
        venderPrefix: "jk",
      },
      {
        pic: "naga-camps-op.png",
        location: "/camps/naga_games",
        alt: "Naga Games",
        flag: "",
        flagtitle: "",
        isEnable: true,
        isHisLobby: false,
        venderPrefix: "naga",
      },
      {
        pic: "gclub-camps-op.png",
        location: "/camps/gclub_casino",
        alt: "Royal Gclub",
        flag: "",
        flagtitle: "",
        venderPrefix: "rcg",
        isEnable: true,
        isHisLobby: true,
      },
      {
        pic: "ka-camps-op.png",
        location: "/camps/ka_gaming",
        alt: "KA Gaming",
        flag: "",
        flagtitle: "",
        venderPrefix: "ka",
        isEnable: true,
        isHisLobby: true,
      },

      {
        pic: "ufabet-camps-op.png",
        location: "/camps/ufabet",
        alt: "UFABET SPORT CASINO BETTING",
        flag: "",
        flagtitle: "",
        venderPrefix: "ufabet",
        isEnable: true,
        isHisLobby: true,
      },
    ],

    IconMenuCategoryCasino: [
      {
        pic: "slot-snam-op.png",
        bg: "shadow bg-op-slot",
        location: "/",
        title: "สล็อต",
        category: "สล็อต",
        disable: false,
      },

      {
        pic: "casino-snam-op.png",
        bg: "shadow bg-op-casino",
        location: "/",
        title: "คาสิโนสด",
        category: "คาสิโนสด",
        disable: false,
      },
      {
        pic: "sport-snam-op.png",
        bg: "shadow bg-op-sport",
        location: "/",
        title: "กีฬา",
        category: "กีฬา",
        disable: false,
      },
      {
        pic: "fishing-snam-op.png",
        bg: "shadow bg-op-fishing",
        location: "/",
        title: "ยิงปลา",
        category: "ยิงปลา",
        disable: false,
      },
      {
        pic: "table-snam-op.png",
        bg: "shadow bg-op-table",
        location: "/",
        title: "เกมโต๊ะ",
        category: "เกมโต๊ะ",
        disable: false,
      },
      {
        pic: "lotto-snam-op.png",
        bg: "shadow bg-op-lotto",
        location: "/",
        title: "หวย",
        category: "หวย",
        disable: false,
      },
      {
        pic: "scratch-snam-op.png",
        bg: "shadow bg-op-ufabet",
        location: "/",
        title: "ขูดบัตร",
        category: "ขูดบัตร",
        disable: false,
      },
      {
        pic: "arcade-snam-op.png",
        bg: "shadow bg-op-slotxo",
        location: "/",
        title: "อาร์เคด",
        category: "เกมอาร์เคด",
        disable: false,
      },
    ],
    IconCampeFooter: [],
  };
};
