export const searchActionTypes = {
  ACTION_SEARCH: "ACTION_SEARCH",
};

export interface SearchInitialStateProps {
  searchText: string;
}

const searchInitialState: SearchInitialStateProps = {
  searchText: "",
};

export default function reducer(
  state = searchInitialState,
  action: any
): SearchInitialStateProps {
  switch (action.type) {
    case searchActionTypes.ACTION_SEARCH:
      return {
        ...state,
        searchText: action.payload,
      };
    default:
      return state;
  }
}
