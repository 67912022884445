import { GetInfoResponse } from "../../worker/api/getinfo";
import { GetTransactionGetMemberListOfDepositMoneyRepsonse } from "lib/worker/api/get_transaction_get_member_list_of_deposit_money";
import { GetVenderBankAccountFromMemberByIDResponse } from "lib/worker/api/get_vender_bank_account_from_member_by_id";
import { GetTransactionHistoryDepositAndWithdrawResponse } from "lib/worker/api/get_transaction_history_deposit_and_withdraw";
import { GetAllGamesResponseResult } from "lib/worker/api/get_all_game";
import { GetTransactionMemberHistoryGameResponse } from "lib/worker/api/get_transaction_member_history_game";
import { GetTransactionByGameIDTotalAmountGetWinResponse } from "lib/worker/api/get_transaction_by_game_id_total_amount_get_win";
import { GetAllLiveCasinoResponse } from "lib/worker/api/get_all_live_casino";
import { GetAllGameHome } from "lib/worker/api/get_all_game_home";
import { GetAllGameNotFilterVender } from "lib/worker/api/get_all_game_not_filtter_vender";
import { GetTransactionGetHistoryLastPlayGame } from "lib/worker/api/get_transaction_get_history_last_play_game";
import { GetTransactionMemberHistorySMPointGameResponse } from "lib/worker/api/get_transaction_member_history_game_byMember_sm_point";
import { LoginResponse } from "lib/worker/api/login";
import { AnyAction } from "redux";
import { GetVenderDomainInfomationResponse } from "lib/worker/api/get_vender_domain_infomation";
import { GetPromotionRemaingingResponse } from "lib/worker/api/get_promotion_remaining";
import { GetAllBlogsResponse } from "lib/worker/api/get_all_blogs";

export const memberActionTypes = {
  ACTION_USER_LOGIN: "ACTION_USER_LOGIN",
  ACTION_STATUS_IS_LOGIN: "ACTION_STATUS_IS_LOGIN",
  ACTION_USER_LOGOUT: "ACTION_USER_LOGOUT",
  ACTION_ADD_USERNAME: "ACTION_ADD_USERNAME",
  ACTION_USER_UPDATE_INFO: "ACTION_USER_UPDATE_INFO",
  ACTION_USER_LOADING: "ACTION_USER_LOADING",
  ACTION_USER_UPDATE_TRANSACTION_LIST_DEPOSIT:
    "ACTION_USER_UPDATE_TRANSACTION_LIST_DEPOSIT",
  ACTION_USER_UPDATE_TRANSACTION_DEPOSIT_AND_WITHDRAW:
    "ACTION_USER_UPDATE_TRANSACTION_DEPOSIT_AND_WITHDRAW",
  ACTION_USER_UPDATE_TRANSACTION_PLAY_HISTORY:
    "ACTION_USER_UPDATE_TRANSACTION_PLAY_HISTORY",
  ACTION_USER_UPDATE_TRANSACTION_UDIAMOND:
    "ACTION_USER_UPDATE_TRANSACTION_UDIAMOND",
  ACTION_USER_UPDATE_BANK_VENDER: "ACTION_USER_UPDATE_BANK_VENDER",
  ACTION_USER_UPDATE_GAME_ALL: "ACTION_USER_UPDATE_GAME_ALL",
  ACTION_UPDATE_HOME_GAME: "ACTION_UPDATE_HOME_GAME",
  ACTION_UPDATE_ALL_VENDER_GAME: "ACTION_UPDATE_ALL_VENDER_GAME",
  ACTION_USER_UPDATE_GAME_LIVE_CASINO: "ACTION_USER_UPDATE_GAME_LIVE_CASINO",
  ACTION_USER_UPDATE_GAME_FAVORITE: "ACTION_USER_UPDATE_GAME_FAVORITE",
  ACTION_USER_UPDATE_GAME_LAST_PLAY: "ACTION_USER_UPDATE_GAME_LAST_PLAY",
  ACTION_OPEN_MODAL_DEPOSIT_SUCCESS: "ACTION_OPEN_MODAL_DEPOSIT_SUCCESS",
  ACTION_OPEN_MODAL_DEPOSIT_REJECT: "ACTION_OPEN_MODAL_DEPOSIT_REJECT",
  ACTION_OPEN_MODAL_DEPOSIT_CLOSE: "ACTION_OPEN_MODAL_DEPOSIT_CLOSE",
  ACTION_OPEN_MODAL_WITH_MESSAGE_OPEN: "ACTION_OPEN_MODAL_WITH_MESSAGE_OPEN",
  ACTION_OPEN_MODAL_WITH_MESSAGE_CLOSE: "ACTION_OPEN_MODAL_WITH_MESSAGE_CLOSE",
  ACTION_OPEN_MODAL_WITH_ERROR_PROMOTION_OPEN:
    "ACTION_OPEN_MODAL_WITH_ERROR_PROMOTION_OPEN",
  ACTION_OPEN_MODAL_WITH_ERROR_PROMOTION_OPEN_CLOSE:
    "ACTION_OPEN_MODAL_WITH_ERROR_PROMOTION_OPEN_CLOSE",
  ACTION_OPEN_MODAL_ALL_CLOSE: "ACTION_OPEN_MODAL_ALL_CLOSE",
  ACTION_OPEN_MODAL_WITH_GAME_MODAL_OPEN:
    "ACTION_OPEN_MODAL_WITH_GAME_MODAL_OPEN",
  ACTION_OPEN_MODAL_WITH_GAME_MODAL_MESSAGE_CLOSE:
    "ACTION_OPEN_MODAL_WITH_GAME_MODAL_MESSAGE_CLOSE",
  ACTION_GET_AMOUNT_TOTAL_WIN_BY_GAME: "ACTION_GET_AMOUNT_TOTAL_WIN_BY_GAME",
  ACTION_OPEN_MODAL_CHECK_OUT: "ACTION_OPEN_MODAL_CHECK_OUT",
  ACTION_CLSE_MODAL_CHECK_OUT: "ACTION_CLSE_MODAL_CHECK_OUT",
  ACTION_OPEN_MODAL_RPS_HOW_TO: "ACTION_OPEN_MODAL_RPS_HOW_TO",
  ACTION_CLOSE_MODAL_RPS_HOW_TO: "ACTION_CLOSE_MODAL_RPS_HOW_TO",
  ACTION_OPEN_MODAL_ALERT_APOLOGIZE_MODAL:
    "ACTION_OPEN_MODAL_ALERT_APOLOGIZE_MODAL",
  ACTION_CLOSE_MODAL_ALERT_APOLOGIZE_MODAL:
    "ACTION_CLOSE_MODAL_ALERT_APOLOGIZE_MODAL",
  ACTION_JACKPOT_DEPOSIT_UPDATE: "ACTION_JACKPOT_DEPOSIT_UPDATE",
  ACTION_UPDATE_GAME_VOL_HIGHT: "ACTION_UPDATE_GAME_VOL_HIGHT",
  ACTION_UPDATE_ALL_GAME: "ACTION_UPDATE_ALL_GAME",
  ACTION_UPDATE_DOMAIN_INFOMATION: "ACTION_UPDATE_DOMAIN_INFOMATION",
  ACTION_UPDATE_THEME_COLOR: "ACTION_UPDATE_THEME_COLOR",
  ACTION_UPDATE_PROMOTIONS_REMANINING: "ACTION_UPDATE_PROMOTIONS_REMANINING",
  ACTION_DEPOSIT_IS_OPEN_MODAL: "ACTION_DEPOSIT_IS_OPEN_MODAL",
  ACTION_DEPOSIT_IS_CLOSE_MODAL: "ACTION_DEPOSIT_IS_CLOSE_MODAL",
  ACTION_WITHDRAW_IS_OPEN_MODAL: "ACTION_WITHDRAW_IS_OPEN_MODAL",
  ACTION_WITHDRAW_IS_CLOSE_MODAL: "ACTION_WITHDRAW_IS_CLOSE_MODAL",
  ACTION_USER_UPDATE_INFO_IS_LOADING: "ACTION_USER_UPDATE_INFO_IS_LOADING",
  ACTION_MENU_IS_OPEN_MODAL: "ACTION_MENU_IS_OPEN_MODAL",
  ACTION_MENU_IS_CLOSE_MODAL: "ACTION_MENU_IS_CLOSE_MODAL",
  ACTION_OPEN_MODAL_RULE_AGENT: "ACTION_OPEN_MODAL_RULE_AGENT",
  ACTION_CLOSE_MODAL_RULE_AGENT: "ACTION_CLOSE_MODAL_RULE_AGENT",
  ACTION_UPDATE_COMMISSION_RETURN_TO_LOSS:
    "ACTION_UPDATE_COMMISSION_RETURN_TO_LOSS",
  ACTION_UPDATE_BLOGS: "ACTION_UPDATE_BLOGS",
  ACTION_OPEN_MODAL_CATEGORY_MODAL: "ACTION_OPEN_MODAL_CATEGORY_MODAL",
  ACTION_CLOSE_MODAL_CATEGORY_MODAL: "ACTION_CLOSE_MODAL_CATEGORY_MODAL",
  ACTION_OPEN_MODAL_CAMPE_GAME: "ACTION_OPEN_MODAL_CAMPE_GAME",
  ACTION_CLOSE_MODAL_CAMPE_GAME: "ACTION_CLOSE_MODAL_CAMPE_GAME",
};

export type OnActionIsLogin = (isLogin: boolean) => void;
export type OnActionUpdateLastHistoryGame = (
  lastHistoryPlayGmaes: GetTransactionGetHistoryLastPlayGame[]
) => void;
export type OnActionUpdateFavorite = (
  favoriteMyGames: GetAllGamesResponseResult[]
) => void;
export type OnGetAllGameNotFiltterVender = (
  gameAllVender: GetAllGameNotFilterVender
) => void;
export type OnUpdateVenderInfomation = (
  domainInfomation: GetVenderDomainInfomationResponse
) => void;
export type OnUpdateCampeOpen = (
  isModal: boolean,
  allGame: GetAllGamesResponseResult[]
) => void;

export interface MemberInitialStateProps {
  username: string | null;
  token: LoginResponse;
  isLogin: boolean;
  getinfo: GetInfoResponse;
  transactionDeposit: GetTransactionGetMemberListOfDepositMoneyRepsonse[];
  transactionDepositAndWithdraw: GetTransactionHistoryDepositAndWithdrawResponse[];
  bankVender: GetVenderBankAccountFromMemberByIDResponse;
  homeGame: GetAllGameHome;
  gameAllVender: GetAllGameNotFilterVender;
  gameLiveCasino: GetAllLiveCasinoResponse;
  transactionsHistoryPlay: GetTransactionMemberHistoryGameResponse[];
  transactionsHistoryuDiamond: GetTransactionMemberHistorySMPointGameResponse[];
  isModalDepositSuccess: boolean;
  isModalDepositReject: boolean;
  messageSuccessModal: string;
  isModalMessage: boolean;
  modalMessage: string;
  isModalGame: boolean;
  isModalHowToRps: boolean;
  isModalRuleAgent: boolean;
  isModalAlertApologize: boolean;
  isModalCheckOut: boolean;
  isModalCategory: boolean;
  modalGame?: GetAllGamesResponseResult | null;
  saveTempModalGame?: GetAllGamesResponseResult | null;
  amountTotalWinByGame: GetTransactionByGameIDTotalAmountGetWinResponse;
  favoriteMyGames: GetAllGamesResponseResult[];
  lastHistoryPlayGmaes: GetTransactionGetHistoryLastPlayGame[];
  jackpotAmount: number;
  gamesVolHight: GetAllGamesResponseResult[];
  gameAll: GetAllGamesResponseResult[];
  domainInfomation: GetVenderDomainInfomationResponse;
  themeColor: string;
  promotionsremamining: GetPromotionRemaingingResponse[];
  isDepositModal: boolean;
  isWithdrawModal: boolean;
  isGetInfoLoading: boolean;
  isMenuModal: boolean;
  isPromotionErrorModal: boolean;
  commission_return_to_loss: number;
  blogs: GetAllBlogsResponse[];
  isModalOpenCampGame: boolean;
  openCampeGameAllGame: GetAllGamesResponseResult[];
}

const memberInitialState: MemberInitialStateProps = {
  username: null,
  token: {},
  isLogin: false,
  isGetInfoLoading: false,
  getinfo: {
    Id: 0,
    Username: null,
    Balance: 0.0,
    SMPoint: 0.0,
    CurrencyName: "-",
    Country: null,
    CreatedAt: null,
    UpdatedAt: null,
    DisplayCreatedAt: null,
    DisplayUpdatedAt: null,
    IsBlocked: null,
    Context: null,
    CurrencyID: null,
    CasinoMaxBet: null,
    CasinoMinBet: null,
    BankAccount: null,
    BankColor: null,
    BankImage: null,
    BankName: null,
    BankNumber: null,
    Rank: {},
    RankingMemberInfomation: {},
    CalculateLevelRankingModel: {},
    IsMutiBank: false,
  },
  transactionDeposit: [],
  transactionDepositAndWithdraw: [],
  bankVender: {},
  homeGame: {
    SlotPoppular: [],
    GameOfTheDay: [],
    Month: [],
    Poppular: [],
    NewGame: [],
  },
  gameAllVender: {
    AESexy: [],
    SA: [],
    PragmaticPlay: [],
    Joker: [],
    AG: [],
    YGG: [],
    YLFishing: [],
    PG: [],
    DragoonSoft: [],
    QueenMaker: [],
    RedTiger: [],
    OneGame: [],
    Jili: [],
    FaChai: [],
    GetAllGameStartHit: {
      AESexy: 0,
      SA: 0,
      PragmaticPlay: 0,
      Joker: 0,
      AG: 0,
      YGG: 0,
      YLFishing: 0,
      PG: 0,
      DragoonSoft: 0,
      QueenMaker: 0,
      RedTiger: 0,
      OneGame: 0,
      Jili: 0,
      FaChai: 0,
    },
  },
  gameLiveCasino: {
    LiveCasinoPragmatic: [],
    TableCasinoPragmatic: [],
  },
  transactionsHistoryPlay: [],
  transactionsHistoryuDiamond: [],
  isModalDepositSuccess: false,
  isModalDepositReject: false,
  isModalAlertApologize: false,
  messageSuccessModal: "",
  isModalMessage: false,
  modalMessage: "",
  isModalGame: false,
  isModalCategory: false,
  modalGame: null,
  saveTempModalGame: null,
  amountTotalWinByGame: { AfterBetAmount: 0, IsFavorite: false },
  favoriteMyGames: [],
  isModalHowToRps: false,
  isModalRuleAgent: false,
  isModalCheckOut: false,
  isDepositModal: false,
  isWithdrawModal: false,
  lastHistoryPlayGmaes: [],
  jackpotAmount: 0,
  gamesVolHight: [],
  gameAll: [],
  domainInfomation: { Prefix: "", Logo: "" },
  themeColor: `:root {
    /* Background Color */
    --background_website_color: linear-gradient(
      180deg,
      #2a2e54 50%,
      #1a1d35 100%
    );
    --background_card_color: #181c38;
    --background_nav_color: #192042;
    --background_campgame_color: linear-gradient(
      180deg,
      #1a1d35 50%,
      #2a2e54 100%
    );
    --background_dropdown_color: #12162c;
    --background_dropdown_color_hover: #296577;
    --background_chat_send: #5fee7e;
    --background_chat_vender: #091027;
    --background_chat_bottom: #161a33;
    --background_chat_field: #1a1d3c;
    /* Button Color */
    --button_submit_ok: linear-gradient(135deg, #de006f 0%, #f73736 100%);
    --button_submit_withdraw: linear-gradient(135deg, #0073de 0%, #36b0f7 100%);
    --button_submit_ugames: #fdc243;
    --button_close: #333333;
    --button_back: linear-gradient(135deg, #de006f 0%, #f73736 100%);
    --button_login: linear-gradient(140deg, #73baf5 0%, #17c9ff 100%);
    --button_register: linear-gradient(140deg, #fdc243 0%, #f1ba42 100%);
    --border_button_color: #de006f;
    --border_info_color: #2a5375 !important;
    /* Font Color */
    --font_base_color: #fbfeff !important;
    --font_card_color: #ffffff;
    --font_span_color: #0ebaee;
    --font_alert: #e71f33;
    --font_button_submit_ok_color: #ffffff;
    --font_button_submit_withdraw_color: #ffffff;
    --font_button_submit_ugames_color: #ffffff;
    --font_button_login_color: #ffffff;
    --font_button_regiter_color: #ffffff;
    --font_button_close_color: #ffffff;
    --font_button_hover_color: #ffffff;
    --font_chat_send_color: #000000;
    --font_chat_vender_color: #ffffff;
  }
  `,
  promotionsremamining: [],
  isMenuModal: false,
  isPromotionErrorModal: false,
  commission_return_to_loss: 0,
  blogs: [],
  isModalOpenCampGame: false,
  openCampeGameAllGame: [],
};

export default function reducer(
  state = memberInitialState,
  action: AnyAction
): MemberInitialStateProps {
  switch (action.type) {
    case memberActionTypes.ACTION_USER_LOGIN:
      return {
        ...state,
        token: action.payload,
        isLogin: true,
      };
    case memberActionTypes.ACTION_STATUS_IS_LOGIN:
      return {
        ...state,
        isLogin: action.payload as boolean,
      };
    case memberActionTypes.ACTION_USER_LOGOUT:
      return { ...memberInitialState };
    case memberActionTypes.ACTION_USER_UPDATE_INFO:
      return {
        ...state,
        getinfo: action.payload,
        isLogin: true,
      };
    case memberActionTypes.ACTION_ADD_USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case memberActionTypes.ACTION_USER_UPDATE_TRANSACTION_LIST_DEPOSIT:
      return {
        ...state,
        transactionDeposit: action.payload,
      };
    case memberActionTypes.ACTION_USER_UPDATE_TRANSACTION_DEPOSIT_AND_WITHDRAW:
      return {
        ...state,
        transactionDepositAndWithdraw: action.payload,
      };
    case memberActionTypes.ACTION_USER_UPDATE_GAME_LAST_PLAY:
      return {
        ...state,
        lastHistoryPlayGmaes: action.payload,
      };
    case memberActionTypes.ACTION_USER_UPDATE_BANK_VENDER:
      return {
        ...state,
        bankVender: action.payload,
      };
    case memberActionTypes.ACTION_UPDATE_HOME_GAME:
      return {
        ...state,
        homeGame: action.payload,
      };
    case memberActionTypes.ACTION_UPDATE_ALL_VENDER_GAME:
      return {
        ...state,
        gameAllVender: action.payload,
      };
    case memberActionTypes.ACTION_USER_UPDATE_GAME_LIVE_CASINO:
      return {
        ...state,
        gameLiveCasino: action.payload,
      };
    case memberActionTypes.ACTION_USER_UPDATE_TRANSACTION_PLAY_HISTORY:
      return {
        ...state,
        transactionsHistoryPlay: action.payload,
      };
    case memberActionTypes.ACTION_USER_UPDATE_TRANSACTION_UDIAMOND:
      return {
        ...state,
        transactionsHistoryuDiamond: action.payload,
      };
    case memberActionTypes.ACTION_OPEN_MODAL_DEPOSIT_SUCCESS:
      return {
        ...state,
        isModalDepositSuccess: true,
        messageSuccessModal: action.payload,
      };
    case memberActionTypes.ACTION_OPEN_MODAL_DEPOSIT_REJECT:
      return {
        ...state,
        isModalDepositReject: true,
      };
    case memberActionTypes.ACTION_OPEN_MODAL_DEPOSIT_CLOSE:
      return {
        ...state,
        isModalDepositSuccess: false,
        isModalDepositReject: false,
        messageSuccessModal: "",
      };
    case memberActionTypes.ACTION_OPEN_MODAL_WITH_MESSAGE_OPEN:
      return {
        ...state,
        isModalMessage: true,
        modalMessage: action.payload,
      };
    case memberActionTypes.ACTION_OPEN_MODAL_WITH_MESSAGE_CLOSE:
      return {
        ...state,
        isModalMessage: false,
        modalMessage: "",
      };
    case memberActionTypes.ACTION_OPEN_MODAL_ALL_CLOSE:
      return {
        ...state,
        isModalMessage: false,
        isModalGame: false,
        modalMessage: "",
        isModalCheckOut: false,
        isModalDepositSuccess: false,
        isModalDepositReject: false,
        messageSuccessModal: "",
      };
    case memberActionTypes.ACTION_OPEN_MODAL_WITH_GAME_MODAL_OPEN:
      return {
        ...state,
        isModalGame: true,
        modalGame: action.payload,
        saveTempModalGame: action.payload,
        amountTotalWinByGame: { AfterBetAmount: 0, IsFavorite: false },
      };
    case memberActionTypes.ACTION_OPEN_MODAL_WITH_GAME_MODAL_MESSAGE_CLOSE:
      return {
        ...state,
        isModalGame: false,
        modalGame: null,
      };
    case memberActionTypes.ACTION_OPEN_MODAL_CHECK_OUT:
      return {
        ...state,
        isModalCheckOut: true,
      };
    case memberActionTypes.ACTION_CLSE_MODAL_CHECK_OUT:
      return {
        ...state,
        isModalCheckOut: false,
      };
    case memberActionTypes.ACTION_OPEN_MODAL_RPS_HOW_TO:
      return {
        ...state,
        isModalHowToRps: true,
      };
    case memberActionTypes.ACTION_CLOSE_MODAL_RPS_HOW_TO:
      return {
        ...state,
        isModalHowToRps: false,
      };
    case memberActionTypes.ACTION_OPEN_MODAL_RULE_AGENT:
      return {
        ...state,
        isModalRuleAgent: true,
      };
    case memberActionTypes.ACTION_CLOSE_MODAL_RULE_AGENT:
      return {
        ...state,
        isModalRuleAgent: false,
      };
    case memberActionTypes.ACTION_OPEN_MODAL_CATEGORY_MODAL:
      return {
        ...state,
        isModalCategory: true,
      };
    case memberActionTypes.ACTION_CLOSE_MODAL_CATEGORY_MODAL:
      return {
        ...state,
        isModalCategory: false,
      };
    case memberActionTypes.ACTION_USER_UPDATE_INFO_IS_LOADING: {
      return {
        ...state,
        isGetInfoLoading: action.payload,
      };
    }
    case memberActionTypes.ACTION_DEPOSIT_IS_OPEN_MODAL:
      return { ...state, isDepositModal: true };
    case memberActionTypes.ACTION_DEPOSIT_IS_CLOSE_MODAL:
      return { ...state, isDepositModal: false };
    case memberActionTypes.ACTION_WITHDRAW_IS_OPEN_MODAL:
      return { ...state, isWithdrawModal: true };
    case memberActionTypes.ACTION_WITHDRAW_IS_CLOSE_MODAL:
      return { ...state, isWithdrawModal: false };
    case memberActionTypes.ACTION_OPEN_MODAL_ALERT_APOLOGIZE_MODAL:
      return {
        ...state,
        isModalAlertApologize: true,
      };
    case memberActionTypes.ACTION_CLOSE_MODAL_ALERT_APOLOGIZE_MODAL:
      return {
        ...state,
        isModalAlertApologize: false,
      };
    case memberActionTypes.ACTION_GET_AMOUNT_TOTAL_WIN_BY_GAME:
      return { ...state, amountTotalWinByGame: action.payload };
    case memberActionTypes.ACTION_USER_UPDATE_GAME_FAVORITE:
      return { ...state, favoriteMyGames: action.payload };
    case memberActionTypes.ACTION_JACKPOT_DEPOSIT_UPDATE:
      return { ...state, jackpotAmount: action.payload };
    case memberActionTypes.ACTION_UPDATE_GAME_VOL_HIGHT:
      return { ...state, gamesVolHight: action.payload };
    case memberActionTypes.ACTION_UPDATE_ALL_GAME:
      return { ...state, gameAll: action.payload };
    case memberActionTypes.ACTION_UPDATE_DOMAIN_INFOMATION:
      return { ...state, domainInfomation: action.payload };
    case memberActionTypes.ACTION_UPDATE_THEME_COLOR:
      return { ...state, themeColor: action.payload };
    case memberActionTypes.ACTION_UPDATE_PROMOTIONS_REMANINING:
      return { ...state, promotionsremamining: action.payload };
    case memberActionTypes.ACTION_MENU_IS_OPEN_MODAL:
      return { ...state, isMenuModal: true };
    case memberActionTypes.ACTION_MENU_IS_CLOSE_MODAL:
      return { ...state, isMenuModal: false };
    case memberActionTypes.ACTION_OPEN_MODAL_WITH_ERROR_PROMOTION_OPEN:
      return { ...state, isPromotionErrorModal: true };
    case memberActionTypes.ACTION_OPEN_MODAL_WITH_ERROR_PROMOTION_OPEN_CLOSE:
      return { ...state, isPromotionErrorModal: false };
    case memberActionTypes.ACTION_UPDATE_COMMISSION_RETURN_TO_LOSS:
      return { ...state, commission_return_to_loss: action.payload };
    case memberActionTypes.ACTION_UPDATE_BLOGS:
      return { ...state, blogs: action.payload };
    case memberActionTypes.ACTION_OPEN_MODAL_CAMPE_GAME:
      return {
        ...state,
        isModalOpenCampGame: action.payload.ismodal,
        openCampeGameAllGame: action.payload.campegame,
      };
    case memberActionTypes.ACTION_CLOSE_MODAL_CAMPE_GAME:
      return {
        ...state,
        isModalOpenCampGame: false,
        openCampeGameAllGame: [],
      };

    default:
      return state;
  }
}
