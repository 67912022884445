import ReduxThunk from "redux-thunk";
import count from "./count/reducer";
import tick from "./tick/reducer";
import member, { MemberInitialStateProps } from "./member/reducer";
import chat, { ChatInitialStateProps } from "./chat/reducer";
import search, { SearchInitialStateProps } from "./search/reducer";
import { createWrapper, Context } from "next-redux-wrapper";
import { createStore, Store, combineReducers, applyMiddleware } from "redux";

export interface AppReducerStateProps {
  count: any;
  tick: any;
  member: MemberInitialStateProps;
  chat: ChatInitialStateProps;
  search: SearchInitialStateProps;
}

const AppReducer = combineReducers({
  count,
  tick,
  member,
  chat,
  search,
});

export const store = createStore(AppReducer, applyMiddleware(ReduxThunk));
const makeStore = (_context: Context) => store;
export const wrapper = createWrapper<Store<AppReducerStateProps>>(makeStore, {
  debug: true,
});
